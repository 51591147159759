body {
  padding: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  /* margin: 100px 30px; */
  /* margin-top: 38px; */
  height: 100%;
  overflow: hidden;
}

.stopScroll {
  overflow: hidden;
}

.startScroll {
  overflow: scroll;
}

/* @media (min-width: 768px) {
  html {
    margin: 40px 100px;
  }
} */

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
